module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-remark-autolink-headers@5.25.0_ayfsakcpbqhu5vgns4dhnelt3u/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-analytics@4.25.0_ayfsakcpbqhu5vgns4dhnelt3u/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-R34RFMLRQ8","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@4.25.0_u43o6wn6gd265vyk5gpqhoouei/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0b7d1aef4ee8f7933a1bfdde5ef69425"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@4.25.0_qyabkb5h6b7mifciv426iu4uca/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
